/* eslint-disable max-lines-per-function, max-statements, no-undefined, max-lines */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DEFAULT_HOME_VIDEO_REVIEW } from "contexts/Reviews";
import EditActions from "./EditActions";
import equals from "ramda/src/equals";
import getIsHomeVideoReviewReadyToBeUpdated from "./helpers/getIsHomeVideoReviewReadyToBeUpdated";
import getIsUpdateHomeVideoReviewEnabled from "./helpers/getIsUpdateHomeVideoReviewEnabled";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useAddEditHomeVideoReview from "hooks/useAddEditHomeVideoReview";
import useCreateReviews from "hooks/useCreateReviews";
import useReviews from "hooks/useReviews";
const { homeVideoReviews: { UPDATE_HOME_VIDEO_REVIEW } } = mutations;
const { homeVideoReviews: { GET_HOME_VIDEO_REVIEW, SEARCH_HOME_VIDEO_REVIEWS } } = queries;
// Define the service names that will be used in the application
const SERVICE_NAMES = [
    "avForums",
    "avNirvana",
    "avsForum",
    "bluRay",
    "bluRayAuthority",
    "digitalBits",
    "doBlu",
    "hidefDigest",
    "homeTheaterForum",
    "whySoBlu"
];
// Define the default value for a selected Mighty Chroma
const DEFAULT_SELECTED_HOME_VIDEO_REVIEW = "";
// This function prepares the component with the necessary data
const usePrepareComponent = () => {
    // Using custom hooks for various actions and state management
    const { resetSelectedReviews, setOMDbId, setSelectedHomeVideoReview, state: { avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, homeTheaterForum, isUHD, homeVideoReview, whySoBlu } } = useReviews();
    const { areReviewsCalled, areReviewsLoading, onCreateReviews, resetReviews } = useCreateReviews();
    const { onClearAll } = useAddEditHomeVideoReview();
    const [selectedHomeVideoReviewId, setSelectedHomeVideoReviewId] = useState(DEFAULT_SELECTED_HOME_VIDEO_REVIEW);
    // Using Apollo client hooks for data fetching and updating
    const [searchHomeVideoReviews, { data: searchHomeVideoReviewsData, loading: searchHomeVideoReviewsLoading }] = useLazyQuery(SEARCH_HOME_VIDEO_REVIEWS);
    const [updateHomeVideoReview, { called: updateHomeVideoReviewCalled, data: updateHomeVideoReviewData, loading: updateHomeVideoReviewLoading, reset: updateHomeVideoReviewReset }] = useMutation(UPDATE_HOME_VIDEO_REVIEW);
    const [getHomeVideoReview, { loading: getHomeVideoReviewLoading }] = useLazyQuery(GET_HOME_VIDEO_REVIEW);
    // Determine if the Mighty Chroma is ready to be updated
    const isHomeVideoReviewReadyToBeUpdated = useMemo(() => getIsHomeVideoReviewReadyToBeUpdated({
        avForums: avForums.selected,
        avNirvana: avNirvana.selected,
        avsForum: avsForum.selected,
        bluRay: bluRay.selected,
        bluRayAuthority: bluRayAuthority.selected,
        digitalBits: digitalBits.selected,
        doBlu: doBlu.selected,
        hidefDigest: hidefDigest.selected,
        homeTheaterForum: homeTheaterForum.selected,
        homeVideoReview,
        id: selectedHomeVideoReviewId,
        whySoBlu: whySoBlu.selected
    }), [
        homeVideoReview,
        avForums.selected,
        avNirvana.selected,
        avsForum.selected,
        bluRay.selected,
        bluRayAuthority.selected,
        digitalBits.selected,
        doBlu.selected,
        hidefDigest.selected,
        homeTheaterForum.selected,
        selectedHomeVideoReviewId,
        whySoBlu.selected
    ]);
    // If the reviews are submitted, check if the Mighty Chroma is ready to be updated and update it if necessary
    useEffect(() => {
        if (areReviewsCalled) {
            if (isHomeVideoReviewReadyToBeUpdated && !updateHomeVideoReviewCalled) {
                const { imageServiceName, ...preparedHomeVideoReview } = homeVideoReview;
                updateHomeVideoReview({
                    variables: {
                        homeVideoReview: {
                            ...preparedHomeVideoReview,
                            id: selectedHomeVideoReviewId
                        }
                    }
                });
            }
        }
    }, [
        areReviewsCalled,
        updateHomeVideoReview,
        updateHomeVideoReviewCalled,
        isHomeVideoReviewReadyToBeUpdated,
        homeVideoReview,
        selectedHomeVideoReviewId
    ]);
    // If the Mighty Chroma is updated, reset the selected reviews and Mighty Chroma ID
    useEffect(() => {
        if (updateHomeVideoReviewData) {
            if (!equals(homeVideoReview, DEFAULT_HOME_VIDEO_REVIEW)) {
                resetSelectedReviews();
                updateHomeVideoReviewReset();
            }
            resetReviews();
            setSelectedHomeVideoReviewId("");
        }
    }, [
        homeVideoReview,
        resetReviews,
        resetSelectedReviews,
        setSelectedHomeVideoReviewId,
        updateHomeVideoReviewData,
        updateHomeVideoReviewReset
    ]);
    // Determine if the update Mighty Chroma button should be enabled
    const isUpdateHomeVideoReviewEnabled = useMemo(() => getIsUpdateHomeVideoReviewEnabled({
        avForums: avForums.selected,
        avNirvana: avNirvana.selected,
        avsForum: avsForum.selected,
        bluRay: bluRay.selected,
        bluRayAuthority: bluRayAuthority.selected,
        digitalBits: digitalBits.selected,
        doBlu: doBlu.selected,
        hidefDigest: hidefDigest.selected,
        homeTheaterForum: homeTheaterForum.selected,
        homeVideoReview,
        id: selectedHomeVideoReviewId,
        whySoBlu: whySoBlu.selected
    }), [
        avForums.selected,
        avNirvana.selected,
        avsForum.selected,
        bluRay.selected,
        bluRayAuthority.selected,
        digitalBits.selected,
        doBlu.selected,
        hidefDigest.selected,
        homeTheaterForum.selected,
        homeVideoReview,
        selectedHomeVideoReviewId,
        whySoBlu.selected
    ]);
    // Check loading state of various API calls
    const loading = useMemo(() => updateHomeVideoReviewLoading ||
        areReviewsLoading ||
        getHomeVideoReviewLoading ||
        searchHomeVideoReviewsLoading, [
        areReviewsLoading,
        updateHomeVideoReviewLoading,
        getHomeVideoReviewLoading,
        searchHomeVideoReviewsLoading
    ]);
    // When a Mighty Chroma is selected, fetch its data and update the form values
    const onSelectHomeVideoReviewId = (id) => {
        setSelectedHomeVideoReviewId(id ?? "");
        getHomeVideoReview({
            onCompleted: (response) => {
                const data = response.getHomeVideoReview;
                if (data) {
                    if (data.mas.imdbId !== homeVideoReview.OMDbId) {
                        setOMDbId(data.mas.imdbId);
                    }
                    SERVICE_NAMES.forEach((serviceName) => {
                        if (data[serviceName] &&
                            // @ts-ignore
                            data[serviceName].id !== homeVideoReview?.[`${serviceName}Id`]) {
                            setSelectedHomeVideoReview({
                                id: data[serviceName].id,
                                isPublished: data.isPublished,
                                // @ts-ignore
                                serviceName,
                                title: data.mas.title,
                                url: data[serviceName].url
                            });
                        }
                    });
                }
            },
            variables: {
                id
            }
        });
    };
    // Clear all form values and deselect the Mighty Chroma ID
    const onClearAllAndSelected = () => {
        setSelectedHomeVideoReviewId("");
        onClearAll();
    };
    const onGetSuggestions = ({ inputValue }) => {
        searchHomeVideoReviews({
            variables: {
                searchHomeVideoReviewsInput: {
                    isUHD,
                    title: inputValue
                }
            }
        });
    };
    // Return the necessary props for the component
    return {
        homeVideoReviewTitles: searchHomeVideoReviewsData?.searchHomeVideoReviews ?? [],
        isUpdateHomeVideoReviewEnabled,
        loading,
        onClearAll: onClearAllAndSelected,
        onGetSuggestions,
        onSelectHomeVideoReviewId,
        onUpdateHomeVideoReview: onCreateReviews,
        selectedHomeVideoReviewId
    };
};
// Export the prepared component
export default prepareComponent(usePrepareComponent)(EditActions);
