/* eslint-disable max-lines-per-function, max-statements */
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import CREATE_HOME_VIDEO_REVIEWS_IMAGES_GRID from "mutations/createHomeVideoReviewsImagesGrid";
import ImagesGrid from "./ImagesGrid";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
const { homeVideoReviews: { GET_HOME_VIDEO_REVIEW } } = queries;
const ID_VALIDATE = /\S{7}-\S{4}-\S{4}-\S{4}-\S{12}/u;
const ZERO = 0;
const SLICE_END = 80;
const SLICE_START = 0;
const usePrepareComponent = () => {
    const [homeVideoReviewIds, setHomeVideoReviewIds] = useState([""]);
    const [reviewNames, setReviewNames] = useState([]);
    const [getHomeVideoReview] = useLazyQuery(GET_HOME_VIDEO_REVIEW);
    const [createHomeVideoReviewsImagesGrid, { loading: isCreateHomeVideoImagesGridLoading }] = useMutation(CREATE_HOME_VIDEO_REVIEWS_IMAGES_GRID);
    useEffect(() => {
        const hasEmptyRow = homeVideoReviewIds.filter((id) => id === "").length > ZERO;
        homeVideoReviewIds.forEach((homeVideoReviewId, index) => {
            if (homeVideoReviewId && ID_VALIDATE.test(homeVideoReviewId)) {
                getHomeVideoReview({
                    onCompleted: (homeVideoReviewData) => {
                        setReviewNames((prevReviewNames) => {
                            const newReviewNames = [...prevReviewNames];
                            // @ts-ignore
                            newReviewNames[index] = `${homeVideoReviewData?.getHomeVideoReview.summary.total.long.slice(SLICE_START, SLICE_END)}...`;
                            return newReviewNames;
                        });
                    },
                    variables: {
                        id: homeVideoReviewId
                    }
                });
            }
        });
        if (!hasEmptyRow) {
            setHomeVideoReviewIds([...homeVideoReviewIds, ""]);
        }
    }, [getHomeVideoReview, homeVideoReviewIds]);
    const onChangeHomeVideoReviewId = (index) => (event) => {
        const newHomeVideoReviewIds = [...homeVideoReviewIds];
        newHomeVideoReviewIds[index] = event.target.value;
        setHomeVideoReviewIds(newHomeVideoReviewIds);
    };
    const onClickGenerate = () => {
        createHomeVideoReviewsImagesGrid({
            variables: {
                homeVideoReviewsIds: homeVideoReviewIds.filter((id) => id)
            }
        });
    };
    return {
        homeVideoReviewIds,
        isCreateHomeVideoImagesGridLoading,
        onChangeHomeVideoReviewId,
        onClickGenerate,
        reviewNames
    };
};
export default prepareComponent(usePrepareComponent)(ImagesGrid);
