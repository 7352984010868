import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-ternary, no-nested-ternary, max-lines-per-function */
import { H1, H2, Left, Main, Paragraph, Right, Row, Top, Wrapper } from "./styled";
import { Button } from "@bluframe/mightychroma-mightytool-ui";
import { InputText } from "@bluframe/blublocks";
const ONE = 1;
const ImagesGrid = ({ isCreateHomeVideoImagesGridLoading, onChangeHomeVideoReviewId, onClickGenerate, homeVideoReviewIds, reviewNames }) => (_jsxs(Wrapper, { children: [_jsxs(Top, { children: [_jsx(H1, { children: "Images Grid" }), _jsx(Button, { label: "Create Images Grid", loading: isCreateHomeVideoImagesGridLoading, onClick: onClickGenerate })] }), _jsxs(Main, { children: [_jsx(Left, { children: homeVideoReviewIds.map((homeVideoReviewId, index) => (_jsx(Row, { children: _jsx(InputText, { label: `Review Id ${index + ONE}`, name: `homeVideoReviewId-${homeVideoReviewId}`, 
                            // @ts-ignore
                            onChange: onChangeHomeVideoReviewId(index), value: homeVideoReviewId }) }, homeVideoReviewId))) }), _jsx(Right, { children: reviewNames?.map((reviewName, index) => (_jsxs(Row, { children: [_jsx(H2, { children: `Review ${index + ONE}` }), _jsx(Paragraph, { children: reviewName })] }, reviewName))) })] })] }));
export default ImagesGrid;
